/*
 * UiO CSS: Local overrides
 *
 */

@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}
:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

/* Fontsize */
#vrtx-structured-article h1 {
  font-size: 46px;
  font-size: 4.6rem;
  line-height: 46px;
  line-height: 4.6rem;
  margin-top: 20px;
  font-weight: bold;
}

h2 {
  font-size: 24px;
  font-size: 2.4rem;
}
h3 {
  font-size: 22px;
  font-size: 2.2rem;
}
h4 {
  font-size: 20px;
  font-size: 2rem;
}

// removing skiplink to unused element
a[href$="\#bottomnav"] {
  display: none;
}

.not-for-ansatte #head-wrapper {
  background: @color-secondary !important;
  color: @color-light;

  #head {
    height: 175px;
  }
  .header-search {
    top: 60px;
  }
}

.head-menu,
.uio-app-name {
  display: none;
}

.head-description,
.head-title,
.tips {
  color: @color-light;
  position: absolute;
}

.tips a {
  color: @color-light;
}

.head-title a,
.head-description a {
  color: @color-light;
  text-decoration: none;
  &:focus,
  &:hover {
    color: @color-dark;
  }
}

.head-description {
  top: 28px;
  font-family: Georgia;
  font-size: 16px;
  font-size: 1.6rem;
}

.head-title {
  font-size: 7rem;
  line-height: 7rem;
  text-transform: uppercase;
  top: 63px;
}

/* Bold stil på UNIforum i head */
.uni {
  font-weight: bold;
}

#head {
  .head-menu li.language {
    display: none;
  }
  .header-search {
    input[type="text"] {
      background: @color-light;
      color: @color-primary--light;
      .placeholderColor(#777777, #aaaaaa);
    }
    button {
      background: @color-dark;
      color: @color-light;
      &:hover,
      &:focus {
        background: @color-primary--light;
      }
    }
  }
}

#main {
  position: relative;
}

.menu-icons-inner {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  width: 225px;
  justify-content: space-around;
  .tips {
    padding-left: 10px !important;
    color: @color-light;
    position: relative;
    order: 3;
    span {
      display: inline-block;
      position: absolute;
      top: -85px;
      right: 0px;
      width: 120px;
      height: 35px;
      text-align: right;
      a {
        height: 35px;
        padding-left: 42px;
        color: @color-light;
        display: inline-block;
        background: url(../images/speak-bubble-white-bg.svg) no-repeat scroll
          left top;
        background-size: 35px;
        line-height: 35px;
       
      }
    }
  }

  #main .vrtx-social-components .vrtx-share-at-component li.vrtx-share-at-Twitter {
    display: none;
  }
  .fb-globalnav, .bluesky-globalnav {
    margin-top: -85px;
    a {
      width: 35px;
      height: 35px;
      color: transparent;
      display: inline-block;
      transition: 0.2s ease-in-out;
      &:hover,
      &:focus {
        -webkit-filter: invert(100%);
        filter: invert(100%);
      }
    }
  }
  .fb-globalnav {
    order: 1;
    a {
      background: url("../uio1/images/social-list/black-svg/facebook.svg") no-repeat scroll 0 0;
      background-size: 100%;
      margin-left: 10px;
    }
  }
  .bluesky-globalnav {
    order: 2;
    a {
      background: url("../uio1/images/social-list/black-svg/bluesky.svg") no-repeat scroll 0 0;
      background-size: 100%;
      margin-right: 92px;
    }
  }
}

#vrtx-frontpage.vrtx-frontpage-full-width:not(.right-main) .menu-icons {
  position: relative;
  width: 970px;
  margin: 0 auto;
}

/* ^Header */

/* Fonts */

a {
  color: @color-link;
}

/* Fix low contrast problems when red links on grey background */
#left-main a,
.grey-box a {
  color: @color-secondary--shade;
}
.vrtx-comments a:not(.button) {
  color: @color-secondary--shade;
}
body .vrtx-comments {
  .vrtx-comment,
  .vrtx-comment:nth-last-child(2),
  .add-comment #add-comment-webid {
    border-color: @color-light;
  }
  .vrtx-comment,
  .vrtx-comment:nth-last-child(2) {
    border-width: 2px;
  }
}

.vrtx-search-results-container div.vrtx-search-results .result h2 a {
  color: @color-secondary;
}

/* Links hover/focus color override in løpende tekst */

#bottomnav #breadcrumb-container span.vrtx-breadcrumb-level a:focus,
#bottomnav #breadcrumb-container span.vrtx-faculty a:focus,
#bottomnav #breadcrumb-container span.vrtx-uio a:focus,
body:not(#vrtx-frontpage) p.elm-has-own-text a:not([class*="button"]):focus,
body:not(#vrtx-frontpage) span.elm-has-own-text a:not([class*="button"]):focus,
body:not(#vrtx-frontpage) i.elm-has-own-text a:not([class*="button"]):focus,
body:not(#vrtx-frontpage) em.elm-has-own-text a:not([class*="button"]):focus,
body:not(#vrtx-frontpage) bold.elm-has-own-text a:not([class*="button"]):focus,
body:not(#vrtx-frontpage)
  strong.elm-has-own-text
  a:not([class*="button"]):focus,
body:not(#vrtx-frontpage)
  td.elm-has-own-text:not([class*="person-listing"])
  a:not([class*="button"]):focus,
body:not(#vrtx-frontpage)
  ul:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > a:not([class*="button"]):focus,
body:not(#vrtx-frontpage)
  ul:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > *:not(ul):not(ol)
  a:not([class*="button"]):focus,
body:not(#vrtx-frontpage)
  ol:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > a:not([class*="button"]):focus,
body:not(#vrtx-frontpage)
  ol:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > *:not(ul):not(ol)
  a:not([class*="button"]):focus,
#bottomnav #breadcrumb-container span.vrtx-breadcrumb-level a:hover,
#bottomnav #breadcrumb-container span.vrtx-faculty a:hover,
#bottomnav #breadcrumb-container span.vrtx-uio a:hover,
body:not(#vrtx-frontpage) p.elm-has-own-text a:not([class*="button"]):hover,
body:not(#vrtx-frontpage) span.elm-has-own-text a:not([class*="button"]):hover,
body:not(#vrtx-frontpage) i.elm-has-own-text a:not([class*="button"]):hover,
body:not(#vrtx-frontpage) em.elm-has-own-text a:not([class*="button"]):hover,
body:not(#vrtx-frontpage) bold.elm-has-own-text a:not([class*="button"]):hover,
body:not(#vrtx-frontpage)
  strong.elm-has-own-text
  a:not([class*="button"]):hover,
body:not(#vrtx-frontpage)
  td.elm-has-own-text:not([class*="person-listing"])
  a:not([class*="button"]):hover,
body:not(#vrtx-frontpage)
  ul:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > a:not([class*="button"]):hover,
body:not(#vrtx-frontpage)
  ul:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > *:not(ul):not(ol)
  a:not([class*="button"]):hover,
body:not(#vrtx-frontpage)
  ol:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > a:not([class*="button"]):hover,
body:not(#vrtx-frontpage)
  ol:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > *:not(ul):not(ol)
  a:not([class*="button"]):hover {
  background-color: @color-secondary;
  -webkit-box-shadow: 0 0 0 2px @color-secondary;
  -moz-box-shadow: 0 0 0 2px @color-secondary;
  box-shadow: 0 0 0 2px @color-secondary;
}

#main #vrtx-main-content.vrtx-empty-additional-content,
#main #vrtx-main-content.vrtx-hide-additional-content-true,
#main #vrtx-main-content:last-child {
  width: 740px;
}

.vrtx-title a {
  color: @color-secondary !important;
}

body .vrtx-introduction-image {
  max-width: 100%;
  width: 740px;
}

.three-column-feed .item-title,
.kunst .vrtx-feed .item-title,
.tilbakeblikk .vrtx-feed .item-title {
  font-size: 26px;
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 30px;
  line-height: 3rem;
}

.big-image .item-title,
#vrtx-main-content-1 .masonry .item-title {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 40px;
  line-height: 4rem;
  font-weight: bold;
}

#vrtx-main-content-2.third-box-right .item-title {
  font-size: 22px;
  font-size: 2.2rem;
  font-family: arial;
  font-weight: bold;
}

.frontpage-h2 h2 {
  font-family: georgia;
  font-size: 34px;
  font-size: 3.4rem;
  line-height: 1.3;
  margin-bottom: 25px;
}

.vrtx-frontpage-box > h2 a {
  color: @color-dark;
}

.vrtx-frontpage-box .vrtx-box-content h2 {
  margin-bottom: 20px;
}

.vrtx-frontpage-box .vrtx-box-content h2:first-child {
  margin-top: -10px;
}

.vrtx-authors,
.vrtx-date-info,
.published-date,
.vrtx-feed li a.channel,
.vrtx-event-component-misc {
  color: @color-neutral--dark;
}

.vrtx-comment .comment-date {
  color: #626262;
}

#main .big-image {
  .vrtx-feed.with-images ul li a.vrtx-image:first-child {
    width: auto;
    max-width: 100%;
  }

  &.portrait .vrtx-feed.with-images ul li {
    a.vrtx-image:first-child {
      float: left;
      margin-right: 30px;
    }

    .item-title {
      font-size: 26px;
      font-size: 2.6rem;
      line-height: 30px;
      line-height: 3rem;
    }
  }
}

.vrtx-recent-comments ul {
  margin: 0;
}

a.all-comments,
a.all-messages {
  display: inline-block;
  margin-top: 15px;
}

#comments-header-left .header-href {
  font-family: georgia;
  font-size: 34px;
  font-size: 3.4rem;
}

/* Facts box */
body .vrtx-facts-container {
  position: relative;
  background: @facts-primary;
  .fact-headline {
    background: @facts-contrast;
    color: @facts-primary;
    background: @facts-contrast;
    color: @facts-primary;
    margin-top: 10px;
    margin-bottom: 0px;
    display: inline-block;
    margin-left: -20px;
    padding: 0px 14px;
    font-weight: normal;
    p {
      color: white;
      font-family: georgia;
      font-size: 1.7rem;
      padding: 1px;
      margin: 0;
      padding: 2px;
    }
  }
  p {
    font-size: 15px;
    font-size: 1.5rem;
    color: @facts-text;
  }
  li {
    color: @facts-text;
    &:before {
      color: @facts-text;
    }
  }
  a {
    color: @facts-text;
    text-decoration: underline;
  }
}

@media screen and (min-width: 1050px) {
  .vrtx-facts-container {
    position: relative;
    &.vrtx-container-right {
      margin-right: -60px;
    }
    &.vrtx-container-left {
      margin-left: -60px;
    }
  }
}

/* ^Fonts */

/* Hoved-meny */

#globalnav {
  background: @color-primary !important;
  ul li.vrtx-active-item a {
    background: @color-secondary !important;
  }
  ul li a:hover,
  ul li a:focus {
    background: @color-primary--light !important;
  }
}

/* ^Hoved-meny */
#vrtx-structured-article #left-main {
  visibility: hidden;
}

.vrtx-feed ul li {
  margin-left: 0;
}

.vrtx-feed ul li::before {
  display: none;
}

.uniforum-other-universities-news .vrtx-feed ul li .item-title,
.uniforum-other-universities-news .vrtx-feed ul li .channel {
  display: inline;
}

.uniforum-other-universities-news .vrtx-feed ul li .channel {
  font-family: Georgia, serif;
}

/* Siste saker */
.vrtx-frontpage-box.siste-saker {
  margin-bottom: 0;
  .items li {
    .vrtx-image {
      height: 150px;
      overflow: hidden;
    }
    a.item-title {
      color: @color-primary;
    }
  }
}

/*****NEWSLETTER*****/
/* Nyhetsbrev forside */
#main .grid-container.grid-color-grey {
  background-color: @color-primary;
  margin-bottom: 0;
  #mc_embed_signup {
    h2 {
      color: @color-dark;
    }
    color: @color-dark;
  }
}
/* Newsletter Make */
#main .grid-container.row-black-colored {
  background-color: @color-primary;
  width: 100vw;
  margin-left: calc(50% - 50vw - 115px);
  padding-left: calc(50vw + 115px - 50%);
  margin-bottom: -40px;
  padding-bottom: 40px;
  @media (@screen-below-large) {
    margin-left: -245px;
    padding-left: 245px;
  }
  @media (@screen-below-small) {
    margin-left: 0;
    padding-left: 0;
    margin-left: -15px;
    padding-left: 15px;
    margin-bottom: -15px;
  }
}

.not-for-ansatte .row-black-colored .newsletter {
  color: white;
  h2 {
    color: white;
    padding-top: 50px;
  }
  form {
    margin-bottom: 40px;
    margin-top: 35px;
    display: flex;
    @media (@screen-below-small) {
      flex-direction: column;
      width: 80%;
      row-gap: 40px;
      align-items: center;
    }
  }
  input[type="email"] {
    border: 1px solid @color-primary;
    @media (@screen-below-small) {
      width: 80%;
    }
  }
    
  .button-wrapper {
    margin-left: 22px;
    position: relative;
    width: 139px;
    height: 40px;
    @media (@screen-below-small) {
      top: -5px;
      margin-left: 0px;
      height: 43px;
      width: 80%;
    }
    &::before {
      content: "";
      position: absolute;
    }
    .pulsing {
      width: 99%;
      height: 99%;
      border-radius: 5px;
      z-index: 1;
      position: relative;
    }
    input[type="submit"], .button {
      width: 100%;
      height: 100%;
      padding-right: 20px !important;
      padding-left: 0;
      padding-top: 5px;
      position: absolute;
      background-color: @color-secondary !important;
      top: 0;
      z-index: 2;
      margin: 0px !important;
      transition: 0.2s ease-in-out;
      border: 2px solid transparent;
      &:hover,
      &:focus {
        background-color: @color-primary !important;
        border-color: @color-light;
      }
    }
    &:hover .pulsing,
    &:active .pulsing {
      &::before {
        animation: none;
      }
      &::after {
        animation: none;
      }
    }
  }
}

.pulsing {
  width: 99%;
  height: 99%;
  border-radius: 50px;
  z-index: 1;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: inherit;
    top: 0;
    left: 0;
    z-index: 0;
    background: #afaaaa;
    border-radius: inherit;
    animation: pulsing 4s linear infinite;
  }
  &:after {
    animation: pulsing1 4s linear infinite;
  }
}



button.red:hover,
button.red:focus {
  background-color: @color-primary;
}

.not-for-ansatte #main .vrtx-frontpage-box.nyhetsbrev-forside {
  margin-bottom: 0px;
  color: @color-light;
  .row {
    padding-left: 210px;
  }
  h2 {
    color: @color-light;
  }
  .button-wrapper {
    margin-left: 22px;
    top: -8px;
    display: inline-block;
    position: relative;
    width: 139px;
    height: 43px;
    &::before {
      content: "";
      position: absolute;
    }
    .pulsing {
      border-radius: 5px;
    }

    input[type="submit"].button {
      background: none !important;
      background-color: @color-secondary !important;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 2;
      margin: 0;
      transition: 0.2s ease-in-out;
      padding: 5px 18px 5px 18px;
      border: 2px solid transparent;
      &:hover,
      &:focus {
        background-color: @color-primary !important;
        border-color: @color-light;
      }
    }
    &:hover .pulsing,
    &:active .pulsing {
      &::before {
        animation: none;
      }
      &::after {
        animation: none;
      }
    }
  }
}


/* Masonry load more dynamic feed */

#main .three-column-feed.masonry .vrtx-feed {
  margin: 0;
}

#main .three-column-feed.masonry .vrtx-feed:last-child {
  margin: 0 0 40px 0;
}

#main .three-column-feed.masonry .vrtx-feed ul li {
  width: 303px;
  padding: 0;
  margin: 60px 0 0 0;
}

#main .three-column-feed.masonry .vrtx-feed ul li:nth-child(3n + 1),
#main .three-column-feed.masonry .vrtx-feed ul li:nth-child(3n + 2) {
  margin-right: 30px;
}

#main .three-column-feed.masonry {
  hr {
    margin-bottom: 0px;
  }
}

.feed-dynamic-load-more {
  text-align: center;

  hr {
    border-width: 2px;
    margin-top: 50px;
    margin-bottom: 0;
  }
}

.feed-dynamic.masonry.load-more-news .vrtx-feed:first-child ul {
  height: auto !important;
}

#vrtx-related-content .feed-image-over,
.additional-information .feed-image-over,
#vrtx-additional-content .feed-image-over,
.two-column-feed.feed-image-over,
.three-column-feed.feed-image-over,
.four-column-feed.feed-image-over,
[class*="third-box-"].feed-image-over,
[class*="half-box-"].feed-image-over {
  .vrtx-feed.with-images ul li a.vrtx-image:first-child {
    width: auto;
    margin-right: 0;
    margin-bottom: 15px;
    float: none;

    ~ *:not(.vrtx-image) {
      margin-left: 0;
    }
  }
}

/* notert (beskjedmappe)*/
#vrtx-message-listing-create {
  padding-right: 0px !important;
  background-color: @color-secondary !important;
  &:hover,
  &:focus {
    background-color: @color-secondary--shade!important;
  }
  span {
    color: transparent;
    &::before {
      content: "Ny notis";
      color: @color-light;
    }
  }
}

/* Read more and FB-icon */
a.vrtx-more,
div.vrtx-more a,
a.all-messages,
a.all-comments,
a.more-url {
  font-weight: bold;

  &:before {
    background: rgba(0, 0, 0, 0) url("../images/arrow-forward-black.png")
      no-repeat scroll 0 0.7ex;
  }
}

.fb-ikon {
  background: rgba(0, 0, 0, 0) url("../icons/facebook-icon.png") no-repeat
    scroll left top;
  padding: 5px 0 5px 45px;
  display: inline-block;
}

.comments-title {
  display: none;
}

.vrtx-recent-comments {
  li {
    margin-left: 0 !important;

    &:before {
      content: "" !important;
    }
  }
  span.published-date {
    display: block;
  }
}

.lastModified,
div.time-and-place-container,
.published-date,
.vrtx-event-component-misc,
.vrtx-comment .comment-date {
  font-family: Arial, Helvetica, sans-serif;
}

/* Thirds double */
#main {
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line)
    .vrtx-frontpage-box.third-box-left {
    padding-right: 10px;
    border-right: none;
  }
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line)
    .vrtx-frontpage-box.third-box-right {
    padding-left: 20px;
    border-left: none;
  }
}

/* Increase grey box padding */
#main
  .grid-container.row-all-colored:not(.row-one-colored):not(.row-thirds-double-no-vertical-line) {
  &.row-thirds-double {
    .vrtx-frontpage-box {
      > *:first-child {
        padding-top: 20px;
      }
      > *:last-child {
        padding-bottom: 20px;
      }
    }
  }
}

/* Byline */
.vrtx-authors {
  margin: -20px 0 0 0;
  padding: 0 200px 0 0;
  border-top: none;
}

.vrtx-date-info,
.vrtx-authors + .vrtx-date-info {
  margin: 0 0 40px 0;
  padding: 0 0 20px 0;
  border-bottom: 6px solid @color-lines;
  border-top: none;
}

/* Social components */

#main .vrtx-social-components {
  margin: -15px 0 0 0;
  float: right;

  .vrtx-email-friend,
  .vrtx-share-at-component a {
    padding-left: 35px;
    width: 35px;
    height: 35px;
    overflow: hidden;
    margin-bottom: -12px;
    margin-right: 5px;
  }

  .vrtx-social-components-label {
    display: inline-block;
    margin-right: 5px;
  }

  .vrtx-email-friend {
    transition: 0.2s ease-in-out;
    &:hover,
    &:focus {
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }
  }

  .vrtx-share-at-component {
    ul {
      overflow: visible;
    }
    li {
      background: none;
      &.vrtx-share-at-Facebook a,
      &.vrtx-share-at-Facebook-true a,
      &.vrtx-share-at-Bluesky a,
      &.vrtx-share-at-Bluesky-true a  {
        transition: 0.5s ease-in-out;
        background-size: 100%;
        background-repeat: no-repeat;
        &:hover,
        &:focus {
          -webkit-filter: invert(100%);
          filter: invert(100%);
        }
      }
      &.vrtx-share-at-Facebook a,
      &.vrtx-share-at-Facebook-true a {
        background-image: url(../uio1/images/social-list/black-svg/facebook.svg);
      }
      &.vrtx-share-at-Bluesky a,
      &.vrtx-share-at-Bluesky-true a {
        background-image: url("../uio1/images/social-list/black-svg/bluesky.svg");
      }
    }
  }
}

#vrtx-frontpage .vrtx-recent-comments .item-description {
  display: none;
}

/* Temasiden */

.theme-box.col-1-4 {
  margin-bottom: 45px;
}

.vrtx-tags-element {
  padding-bottom: 5px;
}

#tag-images,
#vrtx-tags {
  margin-top: 30px;
}

// portrait-feed

.portrait {
  .vrtx-image {
    width: 250px !important;
  }
}

/* Footer */

h2.menu-label {
  margin-bottom: 11px !important;
}

#bottomnav {
  display: none !important;
}

#footer-wrapper {
  background: @color-secondary !important;
  padding-bottom: 75px;

  #footers {
    position: relative;
    background-image: none;

    .social-components {
      bottom: 0;
      margin-bottom: -50px;
      margin-right: 25px;
      padding: 14px;
      position: absolute;
      right: 0;
      a {
        background-size: 100%;
        display: inline-block;
        border-radius: 50px;

        //&.mailto,
        &.facebook,
        &.bluesky {
          color: transparent;
          margin-left: 15px;
          padding: 0 0 17px 22px;
          width: 45px;
          height: 45px;
          transition: 0.2s ease-in-out;
          &:hover,
          &:focus {
            color: transparent;
            -webkit-filter: invert(100%);
            filter: invert(100%);
            box-shadow: none;
          }
        }
        &.facebook {
          background: transparent url("../uio1/images/social-list/black-svg/facebook.svg") no-repeat scroll center center;
        }
        &.bluesky {
          background: transparent url("../uio1/images/social-list/black-svg/bluesky.svg") no-repeat scroll center center;
        }
        // &.mailto {
        //   background: transparent url(".../uio1/images/social-list/black-svg/mail.svg") no-repeat scroll center center;
        // }
      }
    }
  }
}

#footer-wrapper.red #footers .footer-icons a {
  &:hover,
  &:focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
}

/* ^Footer */

/* Search */

#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red),
#main input[type="button"]:not(.red),
#main input[type="submit"]:not(.submit-comment-button):not(.red),
#main input[type="cancel"]:not(.red),
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red) {
  background-color: @color-primary;
}
#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):hover,
#main input[type="button"]:not(.red):hover,
#main input[type="submit"]:not(.submit-comment-button):not(.red):hover,
#main input[type="cancel"]:not(.red):hover,
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):hover,
#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):active,
#main input[type="button"]:not(.red):active,
#main input[type="submit"]:not(.submit-comment-button):not(.red):active,
#main input[type="cancel"]:not(.red):active,
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):active {
  background-color: @color-primary--light;
}

/* Responsive */

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  #nav-offcanvas #lang-link {
    display: none;
  }
  .not-for-ansatte #head-wrapper #head {
    height: auto;
  }
  .tips,
  .fb-globalnav,
  .bluesky-globalnav {
    display: none;
  }
  .head-description,
  .head-title {
    position: static;
    margin-left: 15px;
  }
  .head-description {
    .font-size(12);
    padding-top: 15px;
  }
  .head-title {
    .font-size(42);
    margin-top: -0.27em;
  }

  #vrtx-structured-article h1 {
    .font-size(40);
    .line-height(48);
    margin-top: 15px;
  }

  .vrtx-authors {
    padding-right: 0;
  }

  a.vrtx-more,
  div.vrtx-more a,
  a.all-messages,
  a.all-comments,
  a.more-url {
    background-position: 0 0.9ex;
  }

  #main .vrtx-social-components {
    margin: 10px 0 0 0;
    float: none;
  }

  #footer-wrapper #footers {
    padding-top: 11px;
  }
  .footer-icons {
    left: 15px;
    margin-top: 150px;
  }
  .social-components {
    margin-bottom: -40px !important;
    left: 5px;
  }
  #main #total-main,
  #main #right-main {
    .big-image .vrtx-feed.with-images ul li a.vrtx-image:first-child img {
      max-height: none;
    }
  }
  .portrait .vrtx-image {
    width: 100% !important;
    max-height: none;
  }
  .grid-container.row-black-colored,
  .grid-container.row-grey-colored {
    padding-top: 0;
    padding-bottom: 0;
    .row {
      padding-left: 0px;
      h2 {
        margin-bottom: 10px;
      }
      #mc-embedded-subscribe-form {
        margin-bottom: -20px;
      }
      #mce-EMAIL.email {
        width: 142px;
        padding-left: 8px;
        padding-right: 5px;
      }
    }
  }
  /* Frontpage dynamic feed */
  #main
    #total-main
    .vrtx-frontpage-box
    .vrtx-box-content
    .vrtx-feed
    ul
    li:nth-child(n):not(:last-child) {
    margin-bottom: 40px;
  }

  .grid-container.grid-color-grey {
    margin-bottom: -22px;
    margin-top: 0 !important;
  }
  #vrtx-frontpage #main #total-main .three-column-feed.masonry {
    padding: 0;

    hr {
      display: none;
    }
    .vrtx-feed ul li {
      margin: 0 0 20px 0;
      padding: 0 15px 20px 15px;
      border-bottom: 2px solid @color-lines;
    }
  }

  #vrtx-main-content-1 {
    .vrtx-image {
      margin-bottom: 0 !important;
    }
    .item-1 .item-title {
      font-size: 20px !important;
      font-size: 2rem !important;
      line-height: 32px !important;
      line-height: 3.2rem !important;
    }
  }
  #vrtx-main-content-2.third-box-right .item-title {
    font-size: 16px !important;
    font-size: 1.6rem !important;
    line-height: 28px !important;
    line-height: 2.8rem !important;
  }
  #main .three-column-feed.masonry {
    .vrtx-feed {
      &:first-child ul li .item-description {
        display: none;
      }
      ul li {
        .vrtx-image {
          line-height: 1;
        }
        .item-title {
          font-size: 16px;
          font-size: 1.6rem;
        }
      }
    }
    .feed-dynamic-load-more {
      /*margin-top: -30px;*/

      hr {
        display: none;
      }
    }
  }

  /* Fikse slik at fungerer utav UiO-rammeverk - dvs. uten #main */
  .vrtx-frontpage-box.siste-saker .vrtx-feed ul li:nth-child(n),
  .vrtx-frontpage-box.siste-saker .vrtx-feed ul li:nth-child(n) a.vrtx-image {
    float: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .vrtx-frontpage-box.three-column-feed.siste-saker
    .vrtx-feed
    ul
    li:nth-child(n) {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  /* Flytte til UiO-rammeverk */

  /* Untatt for ansatte */
  #menu-wrapper .menu {
    background: @color-primary;

    li a {
      &.toggle-navigation,
      &.toggle-search {
        border-left: none;
        color: @color-light;
      }
      &.toggle-navigation .icon {
        background-image: url("../uio1/profile/images/responsive/nav-icon-active.svg");
      }
      &.toggle-search .icon {
        background-image: url("../uio1/profile/images/responsive/search-icon-active.svg");
      }
    }
  }

  #main .big-image {
    h2,
    a.all-messages,
    .vrtx-more {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .col-1-4:nth-child(n) > * {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Print */

@media print {
  .not-for-ansatte #head-wrapper {
    padding: 20px;
  }
  #main {
    margin: 40px 0 0 0;
  }
  #vrtx-structured-article h1 {
    margin-top: 0;
  }
  .head-description,
  .head-title {
    position: static;
  }
  .tips,
  .fb-globalnav,
  .bluesky-globalnav {
    display: none;
  }
}


@keyframes pulsing {
  0% {
    opacity: 1;
    transform: scaleY(1) scaleX(1);
  }
  20% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.2;
    transform: scaleY(1.8) scaleX(1.4);
  }
  80% {
    opacity: 0;
    transform: scaleY(1.8) scaleX(1.4);
  }
  90% {
    opacity: 0;
    transform: scaleY(1) scaleX(1);
  }
}
@keyframes pulsing1 {
  0% {
    opacity: 1;
    transform: scaleY(1) scaleX(1);
  }
  20% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.2;
    transform: scaleY(1.3) scaleX(1.15);
  }
  80% {
    opacity: 0;
    transform: scaleY(1.3) scaleX(1.15);
  }
  90% {
    opacity: 0;
    transform: scaleY(1) scaleX(1);
  }
}
/*------------------------------------*\
# colour
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #2d2d2e; //mork gra
@color-primary--light: #58585a; //lysere mork gra
@color-secondary: #e32526; //rod
@color-secondary--shade: #ce0606; //rod litt morkere

@color-link: @color-secondary;
@facts-primary: #f3f2a5;
@facts-contrast: @color-primary;
@facts-text: #222;

// Neutral colors
@color-neutral--dark: #707070; //date
@color-neutral--light: #f4f7f8; //lys gra (notert)
@color-lines: #eaeaea;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #2b2b2b;

// Screenwidth
@screen-below-large: ~"max-width: 1000px";
@screen-below-small: ~"max-width: 604px";